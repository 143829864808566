<template>
  <TeamDetailItemEditCard
      confirmation-message="Teamnaam werd succesvol gewijzigd"
      title="Bewerk teamnaam"
      update-store-action="teamsModule/updateTeamName" :update-store-action-payload="{teamName: teamName, teamId: teamId}"
      :teamId="teamId" :promises.sync="promises">
    <template v-slot:form-fields>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field v-model="teamName" persistent-placeholder label="Titel" type="text" :rules="teamNameRules" required/>
        </v-col>
      </v-row>
    </template>
  </TeamDetailItemEditCard>
</template>

<script>

import TeamDetailItemEditCard from '@/views/team/forms/TeamDetailItemEditCard'

export default {
  name: "TeamDetailEditProjectName",
  props: {
    teamId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      teamName: null,
      promises: [
        this.$store.dispatch('teamsModule/fetchTeamName', this.teamId)
            .then((result) => {
              this.teamName = result
            })
      ],
      teamNameRules: [
        v => !!v || 'Klant naam moet ingevuld worden',
      ],
    }
  },
  components: {
    TeamDetailItemEditCard
  }
}
</script>

<style scoped>

</style>